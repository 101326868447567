import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Button, Modal } from "react-bootstrap"
import React, { useState, useContext } from "react"

import AppContext from "../../../Context"
import routes from "../../../constants/routes"
import { changeInfoPlanRequest } from "../../../actions"
import { LoadingIndicator } from "../../../components/LoadingIndicator"

export const PlanItem = ({ plan, disabled, day_left, profile }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { setContext } = useContext(AppContext)

  const [show, setShow] = useState(false)
  const [showUpdatePlanSuccess, setShowUpdatePlanSuccess] = useState(false)
  const handleClose = () => {
    setShow(false)
  }

  const handleCloseUpdatePlan = () => {
    setShowUpdatePlanSuccess(false)
    history.push(routes.PROFILE)
  }

  const handleChangeCard = (plan_id, typeChange) => {
    setShow(false)
    setContext("overlay", <LoadingIndicator />)
    dispatch(
      changeInfoPlanRequest(
        {
          type_change: typeChange,
          plan_id: plan_id
        },
        {
          onSuccess: (response) => {
            if (response.status === 404) {
              toast("失敗しました。")
            } else {
              window.open(response.url, "_self")
            }
            setContext("overlay", null)
          },
          onFailed: () => {
            setContext("overlay", null)
            alert("失敗しました。")
          }
        }
      )
    )
  }

  const handleChangePlanOnly = (plan_id, typeChange) => {
    setShow(false)
    setContext("overlay", <LoadingIndicator />)
    dispatch(
      changeInfoPlanRequest(
        {
          type_change: typeChange,
          plan_id: plan_id
        },
        {
          onSuccess: (response) => {
            if (response.status === 404) {
              toast("失敗しました。")
            } else {
              setShowUpdatePlanSuccess(true)
            }
            setContext("overlay", null)
          },
          onFailed: () => {
            setContext("overlay", null)
            alert("失敗しました。")
          }
        }
      )
    )
  }

  return (
    <div className="payment-item d-flex">
      <div className="wrap-payment-item-left">
        <div className="payment-item-left">
          <div className="payment-item-title">{plan.name}</div>
          <div className="payment-item-price">
            ¥
            {Math.floor(parseFloat(plan.price) / plan.month).toLocaleString(
              "en-US"
            )}
            /月
          </div>
        </div>
        <div className="payment-item-left">
          <div className="payment-item-title-all">お支払い総金額</div>
          <div className="payment-item-price-all">
            ¥{parseFloat(plan.price).toLocaleString("en-US")} (税込)
          </div>
        </div>
      </div>

      <div className="payment-item-right">
        <Button
          className="payment-item-button btn btn-danger"
          onClick={() => {
            if (
              !profile?.contract?.plan_id ||
              profile?.contract?.plan_id === 13
            ) {
              history.push({
                pathname: routes.PAYMENT_TYPE,
                state: {
                  plan_id: plan.plan_id
                }
              })
            } else {
              setShow(true)
            }
          }}
          disabled={
            disabled ||
            (30 <= day_left && day_left <= 90 && plan.month === 1) ||
            (day_left > 90 && (plan.month === 1 || plan.month === 3)) ||
            profile.contract?.plan_type > plan.plan_type ||
            profile.contract?.plan_type === plan.plan_type
          }
        >
          購入する
        </Button>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body className="modal-custom-body px-3 my-3 text-center d-flex flex-column justify-content-around gap-3 align-items-center">
          <Button
            variant="danger"
            size="lg"
            className="btn fs-8 w-100"
            onClick={() => {
              const planId = plan.plan_id
              handleChangePlanOnly(planId, 3)
            }}
          >
            すぐ購入する
          </Button>
          <p className="text-small text-center w-100">
            ※購入するボタンタップで即時決済がされます。キャンセルや返金は出来ませんのでご注意ください。
          </p>
          <Button
            size="lg"
            className="btn-delete-schedule fs-8 w-100"
            onClick={() => {
              const planId = plan.plan_id
              handleChangeCard(planId, 2)
            }}
          >
            クレジットカード変更
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={showUpdatePlanSuccess}
        centered
        onHide={handleCloseUpdatePlan}
        size="sm"
        closeButton
      >
        <Modal.Body className="p-0 py-3 text-center">
          <div className="text-center p-3 text-grey-bold w-100">
            決済完了しました。プランアップしてお相手に目立つように表示されます。
          </div>
          <Button
            variant="danger"
            size="lg"
            className="btn m-0 fs-8 mt-3"
            style={{ width: 150 }}
            onClick={handleCloseUpdatePlan}
          >
            OK
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  )
}
